import {
  ChartResponse,
  DataConfig,
  KpiResponse,
  SearchFilters,
  Tooltip,
  Tooltips,
} from '@lbmx/analytics';
import { AvailableWidget } from '../dashboard-loader/dashboard-loader.ui';
import { ColumnsConfig } from '../report-loader/report-loader.ui';

export interface ChartConfiguration {
  type: string;
  label: string;
  xAxisField: string;
  yAxisField: string;
  legendField: string;
  toolTips: ToolTipConfig[];
  supportedFilters: string[];
  query: SearchFilters;
  columns?: ColumnsConfig[];
  valueField?: string;
  valueFormattedField?: string;
  varianceField?: string;
  varianceFormattedField?: string;
}

export interface ToolTipConfig {
  label: string;
  field: string;
  format?: string;
}

export interface DatasetConfig {
  label: string;
  data: any[];
  tooltips: Tooltip[];
  isProjected?: boolean;
}

export type Response = DataPoint[];
export interface DataPoint {
  [key: string]: number | string;
}

export const transformResponse = (
  response: { records: Response },
  {
    xAxisField,
    yAxisField,
    legendField,
    toolTips,
    type,
    columns,
    valueField: kpiValueField,
    valueFormattedField,
    varianceField,
    varianceFormattedField,
  }: ChartConfiguration
): ChartResponse => {
  if (type === 'table-grid') {
    return {
      columns,
      data: { labels: [], datasets: [], results: response.records },
    };
  }
  if (type === 'variance-gauge') {
    return {
      columns,
      data: {
        labels: [],
        datasets: [],
        results: response.records,
        config: {
          value: response.records[0] ? response.records[0][kpiValueField] : 0,
          deltaValue: response.records[0][varianceField] as number,
          delta: (response.records[0]
            ? response.records[0][varianceFormattedField]
            : '0%'
          ).toString(),
        },
      },
    };
  }

  const newToolTip = toolTips ?? [];
  const ifHorizontal = type.toLowerCase().includes('horizontal');
  const dimField = ifHorizontal ? yAxisField : xAxisField;
  const valueField = ifHorizontal ? xAxisField : yAxisField;
  const ifLegendField = !!legendField;
  return ifLegendField
    ? {
        columns,
        data: response.records.reduce(
          (
            { labels, datasets, ...rest }: DataConfig,
            dataPoint: DataPoint
          ): DataConfig => {
            const newLabels = isNewLabel(dataPoint[dimField], labels);
            const ifDatasetExists = !!datasets.find(
              (dataset) => dataset.label === dataPoint[legendField].toString()
            );
            const newDataSets: DatasetConfig[] = ifDatasetExists
              ? [
                  ...datasets.map((dataset) => {
                    if (dataset.label === dataPoint[legendField].toString()) {
                      const data = [...dataset.data];
                      data[newLabels.indexOf(dataPoint[dimField].toString())] =
                        dataPoint[valueField];
                      const tooltips = [...dataset.tooltips];
                      tooltips[
                        newLabels.indexOf(dataPoint[dimField].toString())
                      ] = newToolTip.reduce(
                        (toolTip: Tooltip, config: ToolTipConfig): Tooltip => {
                          return [
                            ...toolTip,
                            {
                              label: config.label,
                              value: dataPoint[config.field],
                            },
                          ];
                        },
                        []
                      );
                      return {
                        ...dataset,
                        data,
                        tooltips,
                      };
                    }
                    return { ...dataset };
                  }),
                ]
              : [
                  ...datasets,
                  {
                    label: dataPoint[legendField].toString(),
                    data: addToData(
                      newLabels.indexOf(dataPoint[dimField].toString()),
                      dataPoint[valueField]
                    ),
                    tooltips: addToTooltip(
                      newLabels.indexOf(dataPoint[dimField].toString()),
                      newToolTip,
                      dataPoint
                    ),
                    isProjected: dataPoint?.isProjected === 1,
                  },
                ];
            return {
              labels: [...newLabels],
              datasets: [...newDataSets],
              ...rest,
            };
          },
          { labels: [], datasets: [], results: response.records }
        ),
      }
    : {
        columns,
        data: response.records.reduce(
          (
            { labels, datasets, ...rest }: DataConfig,
            dataPoint: DataPoint
          ): DataConfig => {
            const newDataSets: DatasetConfig = {
              label: datasets[0].label,
              data: [...datasets[0].data, dataPoint[valueField]],
              tooltips: [
                ...datasets[0].tooltips,
                newToolTip.reduce(
                  (toolTip: Tooltip, config: ToolTipConfig): Tooltip => {
                    return [
                      ...toolTip,
                      { label: config.label, value: dataPoint[config.field] },
                    ];
                  },
                  []
                ),
              ],
              isProjected: dataPoint?.isProjected === 1,
            };
            const newLabels = [...labels, dataPoint[dimField].toString()];
            return {
              labels: [...newLabels],
              datasets: [{ ...newDataSets }],
              ...rest,
            };
          },
          {
            labels: [],
            datasets: [{ label: 'data', data: [], tooltips: [] }],
            results: response.records,
          }
        ),
      };
};

const isNewLabel = (
  xAxisFieldPoint: string | number,
  labels: string[]
): string[] => {
  if (xAxisFieldPoint && labels.indexOf(xAxisFieldPoint.toString()) === -1) {
    return [...labels, xAxisFieldPoint.toString()];
  }
  return labels;
};

const addToData = (index: number, value: any): any[] => {
  const data = [];
  data[index] = value;
  return [...data];
};

const addToTooltip = (
  index: number,
  toolTip: ToolTipConfig[],
  dataPoint: DataPoint
): Tooltips => {
  const tooltip: Tooltips = [];
  tooltip[index] = toolTip.reduce(
    (toolTips: Tooltip, config: ToolTipConfig): Tooltip => {
      return [
        ...toolTips,
        { label: config.label, value: dataPoint[config.field] },
      ];
    },
    []
  );
  return [...tooltip];
};

export const kpiAdaptor = (
  response: { records: Response },
  config: AvailableWidget,
  type: string
): KpiResponse => {
  switch (type) {
    case 'variance-gauge':
      return {
        value: response.records[0] ? response.records[0][config.valueField] : 0,
        deltaValue: response.records[0][config.varianceField] as number,
        delta: (response.records[0]
          ? response.records[0][config.varianceFormattedField]
          : '0%'
        ).toString(),
      };
    case 'variance':
      return {
        value: (response.records[0]
          ? response.records[0][config.valueFormattedField]
          : '0'
        ).toString(),
        delta: (response.records[0]
          ? response.records[0][config.varianceFormattedField]
          : '0%'
        ).toString(),
        isNegative: response.records[0]
          ? (response.records[0][config.varianceField] as number) < 0
          : false,
      };
    case 'variance-subtitle':
      return {
        value: (response.records[0]
          ? response.records[0][config.valueFormattedField]
          : '0'
        ).toString(),
        delta: (response.records[0]
          ? response.records[0][config.varianceFormattedField]
          : '0%'
        ).toString(),
        isNegative: response.records[0]
          ? (response.records[0][config.varianceField] as number) < 0
          : false,
      };
    case 'dynamic-status':
      const dynamicStatusRecord = response.records[0] || {};
      return {
        value:
          (dynamicStatusRecord[config.valueFormattedField] as string) || '0',
        delta: (
          dynamicStatusRecord[config.varianceFormattedField] || '0%'
        ).toString(),
        deltaValue: (dynamicStatusRecord[config.varianceField] as number) || 0,
        isNegative:
          (dynamicStatusRecord[config.varianceField] as number) < 0 || false,
        maxDate: (dynamicStatusRecord['maxDate'] as string) || null,
        checkMaxDate: (dynamicStatusRecord['checkMaxDate'] as number) || 0,
        variance: (dynamicStatusRecord['variance'] as number) || 0,
        label: (dynamicStatusRecord['label'] as string) || 'No Data',
      };
    default:
      return {
        value: (response.records[0]
          ? response.records[0][config.valueFormattedField]
          : 0
        ).toString(),
      };
  }
};

export const chartNoDataTest = (
  records: { records: Response; totalRecords: number },
  config: AvailableWidget,
  type: string
): boolean => {
  return records.totalRecords === 0 ? true : false;
};
