import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  Router,
  UrlTree,
} from '@angular/router';
import { BaseHttpService, HttpMethod, Request } from '@lbmx/root-services';
import { BehaviorSubject, EMPTY, Subject } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ConfigProvider } from 'src/app/provider/config-provider';

@Injectable({
  providedIn: 'root',
})
export class CampaignPopupService {
  public set request(request: Request) {
    this.wizardRequest$.next(request);
  }
  public get request(): Request {
    return this.wizardRequest$.value;
  }

  public showCampaign$ = new BehaviorSubject<boolean>(false);
  public baseUrl$ = new BehaviorSubject<string>(null);
  public wizardRequest$ = new BehaviorSubject<Request>(null);
  public healthCheckRequest$ = new BehaviorSubject<Request>(null);
  public trigger$ = new Subject<void>();

  private shouldDestroy$ = new Subject<void>();

  constructor(
    public http: BaseHttpService,
    public configPrv: ConfigProvider,
    public router: Router,
    public route: ActivatedRoute
  ) {
    this.baseUrl$.next(configPrv.AppSetting.uriConfiguration.campaign);
    this.wizardRequest$.next({
      endpoint: '/userCampaign',
      httpMethod: HttpMethod.POST,
    });
    this.healthCheckRequest$.next(this.wizardRequest$.value);

    router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd || event instanceof NavigationCancel
        ),
        map(
          () =>
            (router.getCurrentNavigation().initialUrl as UrlTree)?.root
              ?.children?.primary?.segments
        ),
        filter(
          (segments) => Array.isArray(segments) && segments[0]?.path === 'login'
        ),
        tap(() => this.trigger$.next()),

        takeUntil(this.shouldDestroy$)
      )
      .subscribe();

    this.trigger$
      .pipe(
        tap(() => (http.baseUrl = this.baseUrl$.value)),
        // if health check failed or gave an empty response, don't open the wizard popup
        exhaustMap(() =>
          http
            .fetch(this.healthCheckRequest$.value)
            .pipe(catchError(() => EMPTY))
        ),
        filter((resp) => !!resp),
        tap(() => this.showCampaign$.next(true)),
        takeUntil(this.shouldDestroy$)
      )
      .subscribe();
  }
}
