import { Component, ViewEncapsulation } from '@angular/core';
import { CampaignPopupService } from 'src/app/services/campaignPopup/campaignPopup.service';

@Component({
  selector: 'lbmx-campaign-popup',
  templateUrl: './campaignPopup.component.html',
  styleUrls: ['./campaignPopup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignPopupComponent {
  public set showDialog(state: boolean) {
    this.campaignService.showCampaign$.next(state);
  }
  public get showDialog(): boolean {
    return this.campaignService.showCampaign$.value;
  }

  public submit(event: any) {
    this.close();
  }

  public close() {
    this.showDialog = false;
  }

  constructor(public campaignService: CampaignPopupService) {}
}
