import { toolKeys } from '@lbmx/types';
export default {
  '/PIMModule': toolKeys.PIM,
  '/MarketplacePartnersModule': toolKeys.MPP,
  '/AdministrationModule': toolKeys.ADMINISTRATION,
  '/MarketplaceModule': toolKeys.MP,
  '/pay-module': toolKeys.MP_INVOICE_PAY,
  '/ediModule': toolKeys.ANALYTICS,
  '/userProfile': toolKeys.AUTHENTICATED,
  '/analytics-module': toolKeys.ANALYTICS,
};
